<template>
    <div class="member">
      <div class="member-bg"></div>
      <div class="member-main">
        <div class="flex flex-left align-center member-main-goods">
          <div class="member-main-goods-img">
            <img src="@/assets/img/order/kecheng.jpeg" alt="">
          </div>
          <div class="member-main-goods-info">
            <p class="member-main-goods-info-title">流媒体营销密码与私域带货实战课程套装</p>
            <div class="flex flex-between align-center member-main-goods-info-price">
              <div class="member-main-goods-info-price-amount">￥618<span style="display: none;">￥2000</span></div>
              <div class="member-main-goods-info-price-num">x1</div>
            </div>
          </div>
        </div>
        <div class="member-main-pay">
          <div class="flex flex-between align-center member-main-pay-list" >
            <span class="member-main-pay-list-l">支付方式</span>
            <div class="flex flex-right align-center member-main-pay-list-r" :class="checkedPayType == 'ALIPAY' ?'member-main-pay-list-r1':''">
              <img class="member-main-pay-list-r-img1" :src="checkedPayType == 'WXPAY' ? require('@/assets/img/order/wx.png'):require('@/assets/img/order/alipay.png')" alt="">
              {{checkedPayType == 'WXPAY' ? '微信支付' : '支付宝支付'}}
              <img class="member-main-pay-list-r-img2" src="../../../assets/img/order/right.png" alt="">
            </div>
          </div>
        </div>
        <div class="member-main-realinfo">
          <div class="flex flex-between align-center member-main-realinfo-list">
            <div class="member-main-realinfo-list-title">
              <span>*</span>真实姓名
            </div>
            <div class="member-main-realinfo-list-info">
              <input v-model="customerName" type="text" placeholder="请填写真实姓名" />
            </div>
          </div>
          <div class="flex flex-between align-center member-main-realinfo-list">
            <div class="member-main-realinfo-list-title">
              <span>*</span>联系电话
            </div>
            <div class="member-main-realinfo-list-info">
              <input v-model="customerPhone" type="text" placeholder="请填写真实的联系方式，以便老师联系" />
            </div>
          </div>
        </div>
        <div class="member-main-toast">注：课程服务交付后将无法退款</div>
      </div>
      <!-- 订单金额 -->
      <van-popup v-model:show="isShowPay" position="bottom" safe-area-inset-bottom class="order-bottom" :overlay="false">
        <!-- 课程购买协议 -->
        <div class="flex flex-left align-center kecheng">
          <van-checkbox v-model="checkedHttp" icon-size="16px"></van-checkbox>
          <div>查看并同意<span @click="openHttpFunc">《课程购买协议》</span></div>
        </div>
        <div class="flex flex-right align-center member-payinfo">
          <div class="member-payinfo-l">
            <p class="member-payinfo-l-p1">
              共 1 件，<span>实付</span><span>¥618</span>
            </p>
          </div>
          <div class="member-payinfo-btn">
            <van-button type="default" color="#E62129" round class="member-payinfo-btn-anniu" @click="submitOrderFunc">支付</van-button>
          </div>
        </div>
      </van-popup>
    </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>
<style>
.member .van-popup {
  background: transparent;
}
</style>