import {
    submitServiceOrder,
    serviceGoodsWxPay
} from '@/utils/order.js'
import goodsSku from '@/components/goodsSku';
import { provide, ref } from 'vue';
export default {
    components: {
        goodsSku
    },
    setup() {
        let isSku = ref(false); // 商品规格选择弹窗
        provide('issku', isSku);
        let platfrom = ref('ordertrue');
        provide('platfrom', platfrom)
        return {
            isSku
        }
    },
    data() {
        return {
            isShowPage: false,
            isShowPay: true,
            goodsUrl: '',
            checkedPayType: 'WXPAY',
            isDaPerson: false, // true 达人  false 普通会员
            shareMemberId: '',
            customerName: '',
            customerPhone: '',
            checkedHttp: false
        }
    },
    computed: {
        
    },
    created() {
        this.shareMemberId = this.$route.query.shareMemberId;
    },
    methods: {
        // 提交订单
        submitOrderFunc() {
            if (this.customerName == '') {
                this.$toast.fail({message: '请输入真实姓名'});
                return false;
            }
            if (this.customerPhone == '') {
                this.$toast.fail({message: '请输入真实联系方式'});
                return false;
            } else if (!/^1\d{10}$/.test(this.customerPhone)) {
                this.$toast.fail({message: '请输入正确的联系方式'});
                return false;
            }
            if (!this.checkedHttp) {
                this.$toast.fail({message: '请勾选课程购买协议'});
                return false;
            }
            let data = {
                memberId: this.$cookies.get('memberId'),
                goodsId: 0,
                shareMemberId: this.shareMemberId,
                customerName: this.customerName,
                customerPhone: this.customerPhone
            };
            submitServiceOrder(data).then(res => {
                console.log('order', res)
                if (res && res.code == 1) {
                    this.$toast.loading({
                        message: '正在下单中',
                        mask: true,
                        forbidClick: true,
                    });

                    if (this.checkedPayType == 'WXPAY') {
                        // 微信支付
                        if (typeof WeixinJSBridge === 'undefined') { // 微信浏览器内置对象。参考微信官方文档
                            if (document.addEventListener) {
                                document.addEventListener('WeixinJSBridgeReady', this.wxPayFunc(res.result.orderNo, res.result.orderId), false)
                            } else if (document.attachEvent) {
                                document.attachEvent('WeixinJSBridgeReady', this.wxPayFunc(res.result.orderNo, res.result.orderId))
                                document.attachEvent('onWeixinJSBridgeReady', this.wxPayFunc(res.result.orderNo, res.result.orderId))
                            }
                        } else {
                            console.log('准备调用微信支付')
                            this.wxPayFunc(res.result.orderNo, res.result.orderId);
                        }
                    }
                }
            })
        },
        // 调起支付
        wxPayFunc(orderNo, orderId) {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderId: orderNo // orderId=订单号(取submitServiceOrder返回的orderNo)
            }
            let _this = this;
            serviceGoodsWxPay(data).then(res => {
                console.log('微信支付', res)
                    //调用微信支付
                WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        "appId": res.result.sdk_appid, //公众号名称，由商户传入
                        "timeStamp": res.result.sdk_timestamp, //时间戳，自1970年以来的秒数
                        "nonceStr": res.result.sdk_noncestr, //随机串
                        "package": res.result.sdk_package,
                        "signType": res.result.sdk_signtype, //微信签名方式：
                        "paySign": res.result.sdk_paysign //微信签名
                    },
                    function(res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            _this.$toast.success({
                                message: '支付成功！',
                                forbidClick: true,
                            });
                            setTimeout(() => {
                                _this.$toast.clear();
                                _this.$router.push({
                                    path: '/membersPaySuccess',
                                    query: {
                                        orderId: orderId,
                                        from: 1
                                    }
                                })
                            }, 500)
                        }
                        if (res.err_msg == "get_brand_wcpay_request:cancel") {
                            _this.$toast.fail({
                                message: '交易取消！',
                                forbidClick: true,
                            })
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                        }
                        if (res.err_msg == "get_brand_wcpay_request:fail") {
                            _this.$toast('支付失败！');
                        }

                        setTimeout(() => {
                            _this.$toast.clear();
                        }, 1500);
                    }
                );
            })
        },
        // 打开协议
        openHttpFunc() {
            this.$router.push({
                path: '/buyAgreement'
            })
        }
    },
}